import React from "react";
import { Row } from "react-bootstrap";
import SwiperCore, { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Img from "gatsby-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

function ProjectImagesSwiper({ imagesList }) {
  const breakpoints = useBreakpoint();
  return (
    <>
      {breakpoints.sm ? (
        <Row className="projectImagesSwiper mx-0 mb-4">
          <Swiper
            spaceBetween={30}
            slidesPerGroup={1}
            slidesPerView={"auto"}
            setWrapperSize={true}
            direction="horizontal"
            scrollbar={{ draggable: true, parallax: true }}
          >
            {imagesList.map((image) => (
              <SwiperSlide>
                <Img fluid={image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
      ) : null}
    </>
  );
}

export default ProjectImagesSwiper;
