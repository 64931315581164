import React from "react";
import { Row } from "react-bootstrap";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import arrow from "../../../img/arrow.png";

function ProjectsNavigation({ nextProject, prevProject, selectedLanguage }) {
  const getLocalLink = (link) =>
    selectedLanguage === "en" ? link : "/pt" + link;
  return (
    <Row className="pages-selector-container">
      <AniLink
        cover
        direction="left"
        to={getLocalLink(prevProject.link)}
        bg="#fa4180"
        className="pages-selector pages-selector-side pages-selector-l col-6"
      >
        <div className="pages-selector-paragraph">
          <img src={arrow} alt="" className="pages-selector-arrow-left" />
          Previous
        </div>
        <div className="pages-selector-h3">{prevProject.name}</div>
      </AniLink>
      <AniLink
        cover
        direction="right"
        to={getLocalLink(nextProject.link)}
        bg="#fa4180"
        className="pages-selector pages-selector-side pages-selector-r col-6"
      >
        <div className="pages-selector-paragraph">
          Next
          <img src={arrow} alt="" className="pages-selector-arrow-right" />
        </div>
        <div className="pages-selector-h3">{nextProject.name}</div>
      </AniLink>
    </Row>
  );
}

export default ProjectsNavigation;
